import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableBaseComponent = _resolveComponent("TableBaseComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TableBaseComponent, {
        id: "customDatatable",
        url: _ctx.url,
        columnDefs: _ctx.columnDefs,
        columns: _ctx.columns,
        drawCallback: $options.drawCallback
      }, null, 8, ["url", "columnDefs", "columns", "drawCallback"])
    ])
  ]))
}