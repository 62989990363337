

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import {api_url} from "@/ayar";
import TableBaseComponent from "@/components/backoffice/table/TableBaseComponent.vue";
import {onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import { l }           from '@/core/helpers/lang';

export default ({
  name: "Musteriler",
  components: {
    TableBaseComponent
  },
  data: () => {
    return {
      url: api_url + 'myCustomers',
      columns: [
        {
          title: `<div class="form-check form-check-sm form-check-custom form-check-solid me-3 ms-2">
                            <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                        </div>`,
          class: 'w-10px pe-2 ',
          settings: {
            data: 'sponsor_id',
            orderable: false,
            searchable: false
          }
        },
        {
          title: l('admin:genel:sponsor_id'),
          settings: {
            data: 'sponsor_id',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:Adı Soyadı'),
          settings: {
            data: 'name',
            orderable: true
          }
        },
        {
          title: l('admin:languages:languages437'),
          settings: {
            data: 'city',
            orderable: true
          }
        },
        {
          title: l('admin:transfer:GSM'),
          settings: {
            data: 'phone',
            orderable: true
          }
        },
        {
          title: l('admin:genel:tarih'),
          settings: {
            data: 'created_at',
            orderable: true,
            searchable: true
          }
        },
        {
          title: l('admin:transfer:gene_toplam'),
          settings: {
            data: 'pay_amount',
            orderable: true
          }
        },
      ],
      columnDefs: [
        {
          "render": function (data, type, row) {
            return `<div class="form-check form-check-sm form-check-custom form-check-solid ms-2">
                                <input
                                class="form-check-input widget-13-check"
                                type="checkbox"
                                value="${row['id']}"/>
                            </div>`;
          },
          "targets": 0,
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + data + "</div>";

          },
          "targets": 1,
          className: 'text-right align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + row.name + ' ' + row.surname + "</div>";

          },
          "targets": 2,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + data + "</div>";

          },
          "targets": 3,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {

            return row.city ? "<div class='text-black-80 fw-bold d-block fs-7' >" + row.city + ' / ' + row.district + "</div>":'';

          },
          "targets": 4,
          className: 'text-left align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + data + "</div>";


          },
          "targets": 5,
          className: 'text-right align-bottom'
        },
        {
          "render": function (data, type, row) {
            return "<div class='text-black-80 fw-bold d-block fs-7' >" + data + "</div>"

          },
          "targets": 6,
          className: 'text-right align-bottom'
        }
      ],

    }
  },
  methods: {
    drawCallback: function (obj) {
      document.querySelectorAll<HTMLElement>('.routerLink').forEach(element => {
        element.addEventListener("click", e => {
          e.preventDefault();
          obj.$router.push({name: element.dataset.name, params: {id: element.dataset.id}})
        });
      });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle(l('admin:genel:tum_musteriler'));
    });
    return{l}
  }
});
